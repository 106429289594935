<template>
  <div class="videoManage content">
    <div class="contentbox">
      <el-container>
        <el-scrollbar style="min-width: 300px !important; height: 100%">
          <el-aside style="margin-right: 0 !important">
            <div class="tree-container">
              <el-tree
                class="tree filter-tree"
                :data="dataRiver"
                :props="defaultProps"
                :filter-node-method="filterNode"
                :expand-on-click-node="false"
                :default-expanded-keys="defaultExpaneded"
                :highlight-current="true"
                :row-key="id"
                :node-key="id"
                :current-node-key="currentNodekey"
                @node-click="handleNodeClick"
                ref="treeRiver"
              >
                <span slot-scope="{ node, data }">
                  <i v-if="data.children" class="el-icon-folder"></i>
                  <!-- <i v-else class="el-icon-collection-tag"></i> -->
                  <i v-else class="el-icon-tickets"></i>
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
              <div class="p-t-10"></div>
            </div>
          </el-aside>
        </el-scrollbar>
        <el-scrollbar class="wfull">
          <el-main>
            <div ref="rightDevW" class="rightBox">
              <TableQuery
                :formList="formList"
                :formInline="formInline"
                :customArrKey="customArrKey"
                :btnList="btnList"
                :property="queryProperty"
                @btnClickFun="btnClickFun"
                @moveQueryFun="queryBackFun"
                @formDataFun="formDataFun"
              >
              </TableQuery>
              <div class="p-t-10"></div>
              <div ref="tableBox">
                <SuperTable
                  ref="mytable"
                  :tableData="tableData"
                  :loading="tableLoading"
                  :property="tableProperty"
                  :pageData="pageData"
                  @handleCurrentChange="handleCurrentChange"
                  @handleSizeChange="handleSizeChange"
                  @operationClick="operationClick"
                >
                </SuperTable>
              </div>
            </div>
          </el-main>
        </el-scrollbar>
      </el-container>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        v-if="dialogVisible"
        :before-close="dialogClose"
        width="960px"
      >
        <component :is="component" :popupData="popupData"></component>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import TableQuery from "@/common/module/TableQuery";
import SuperTable from "@/common/module/SuperTable";
import directAuthorizationDetail from "./directAuthorizationDetail";
export default {
  name: "directAuthorization",
  components: {
    TableQuery,
    SuperTable,
    directAuthorizationDetail
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      formList: [
        {
          name: "videoname",
          label: "视频名称",
          showhide: true,
          type: "text",
          clearable: true,
          placeholder: "请输入视频名称"
        }
      ],
      formInline: {
        videoname: ""
      },
      customArrKey: [],
      btnList: [
        {
          name: "searchButton",
          icon: "icon-tianjia",
          label: "查询",
          type: "searchButton"
        }
      ],
      queryProperty: {
        labelWidth: "120px",
        typeName: "cedian",
        moveLabelWidth: "120px"
      },
      /* 表格数据 */
      tableData: {
        listData: [],
        titleData: [],
        btnData: []
      },
      /* 表格加载 */
      tableLoading: false,
      /* 表格属性 */
      tableProperty: {
        typeName: "",
        emptyText: "",
        fixheight: false,
        isOrder: true, // 设置表格有序号
        // border: true, // 设置表格有边框
        operationWidth: "140px"
      },
      /* 分页 */
      pageData: {
        currentPage: 1,
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0
      },
      loading: false,
      btnAuthority: false,
      websocket: {},
      videoId: [],
      dataRiver: [],
      defaultExpaneded: [], //默认展开的节点
      defaultProps: {
        children: "nodes",
        label: "text"
      },
      id: "typeCode",
      typecode: "",
      currentNodekey: "", //默认选中的节点树
      popupData: {},
      dialogVisible: false,
      title: "",
      component: ""
    };
  },
  async created() {
    this.$api("videoManage.getVideoTypeTree")
      .invoke()
      .then(({ data: { data } }) => {
        this.dataRiver = [{ typeCode: "", text: "全部", nodes: data.data }];
        this.defaultExpaneded = [this.dataRiver[0].nodes[0].typeCode];
        this.defaultExpaneded = [""];
        this.currentNodekey = "";
        this.$nextTick(() => {
          this.$refs.treeRiver.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
        });
      });
    this.resetArr();
    this.getTableList(true); // 表格列表数据
  },
  methods: {
    /*样式*/
    resetArr() {
      this.tableData.titleData = [
        {
          headeralign: "center",
          prop: "typename",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频类型",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "videoname",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频名称",
          align: "center"
        }
      ];
      this.btnAuthority =
        this.$store.state.operationPower.edit.SHJ_ControllerUnit;
      if (this.btnAuthority) {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          },
          {
            name: "backButton",
            icon: "icon-tianjia",
            label: "返回",
            type: "backButton"
          }
        ];
        this.tableData.btnData = [];
      } else {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          },
          {
            name: "backButton",
            icon: "icon-tianjia",
            label: "返回",
            type: "backButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "editButton",
            icon: "icon-video-shouquan",
            label: "授权",
            type: "editButton"
          }
        ];
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      this.currentNodekey = data.typeCode;
      this.typecode = data.typeCode;
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    getTableList(val) {
      let obj = {
        videoname: this.formInline.videoname,
        typecode: this.typecode,
        page: this.pageData.currentPage,
        rows: this.pageData.pageSize
      };
      this.$api("videoManage.getSdlVideoByListNoFileUrl")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            if (data) {
              this.tableData.listData = data.data;
              this.pageData.total = data.total;
            }
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        });
    },
    /* 当前页-改变事件 */
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.getTableList();
    },
    /* 每页多少条-改变事件 */
    handleSizeChange(val) {
      this.pageData.currentPage = 1;
      this.pageData.pageSize = val;
      this.getTableList();
    },
    // 表格列表上的点击事件
    operationClick(item, scope) {
      console.log("scope", scope);
      if (item.name === "editButton") {
        this.dialogVisible = true;
        this.title = "设置视频二维码权限";
        this.popupData = {
          pkId: scope.row.pkId
        };
        this.component = "directAuthorizationDetail";
      }
    },
    dialogClose() {
      this.dialogVisible = false;
    },
    // 按钮点击事件
    btnClickFun(item) {
      if (item.name === "searchButton") {
        // 查询
        this.pageData.currentPage = 1;
        this.getTableList();
      } else if (item.name === "backButton") {
        this.$router.push({
          path: "/index/leftMenuIndex/qrcodeAuthorization",
          query: {
            menuid: this.$route.query.menuid,
            sysmodel: this.$route.query.sysmodel,
            moduleName: this.$route.query.moduleName
          }
        });
      }
    },
    // 回车查询
    queryBackFun(formInline) {
      this.formInline = formInline;
      // 查询
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    // 查询标签点击事件
    formDataFun(formInline) {
      this.formInline = formInline;
    }
  }
};
</script>
<style scoped lang="less">
.videoManage {
  /deep/.el-table__cell:nth-child(3) {
    cursor: pointer;
  }
  .icon-video-right-1 {
    font-size: 20px;
    color: #41bf3a;
  }
  /deep/.el-table__row {
    .el-table__cell:nth-last-child(1) {
      padding: 0;
    }
  }
  /deep/.el-container {
    .wfull {
      width: 100%;
    }
  }
}
</style>
