<template>
  <div class="directAuthorizationDetail content">
    <div class="contentbox">
      <div class="tips">设置二维码的有效时间</div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="开始时间">
          <el-date-picker
            v-model="ruleForm.begintime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker
            v-model="ruleForm.endtime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <div class="button" @click="submit">保存</div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "directAuthorizationDetail",
  props: {
    popupData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      ruleForm: {
        begintime: "",
        endtime: ""
      }
    };
  },
  created() {},
  methods: {
    submit() {
      const params = {
        fkVideoid: this.popupData.pkId,
        begintime: this.ruleForm.begintime,
        endtime: this.ruleForm.endtime
      };
      this.$api("videoManage.saveVideoUserLimits")
        .invoke(params)
        .then(({ data: { code, data } }) => {
          if (code) {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
              duration: 1000
            });
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "error",
              duration: 1000
            });
          }
        });
    }
  }
};
</script>
<style scoped lang="less">
.tips {
  color: #f00f00;
  padding-left: 32px;
  margin-bottom: 15px;
}
.button {
  display: inline-block;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  padding: 10px 50px;
  border-radius: 3px;
  margin-bottom: 30px;
  margin-left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
</style>
